export const STRAPI_SECTION_IDS = {
  SD_UTILITY_POOL: 'sd-utility-pool',
  AUDITS: 'security',
  ECOSYSTEM: 'ecosystem',
  MEET: 'meet',
  REWARDSCALCULATOR: 'calculator',
  HOWITWORKS: 'works',
  PARTNERS: 'partners',
  SUBSCRIBE: 'subscribe',
  COMMINITY: 'community',
  BLOGS: 'blogs',
  KNOWMORE: 'know-more',
  GUIDE: 'guide',
  FAQS: 'faq',
  FAQSCONTENT: 'know-more-content',
  NODEBANNER: 'node_banner',
  INSURANCEDETAILS: 'insurance',
  NODESPIN: 'node-spin',
};

interface ObjectLiteral {
  [key: string]: any;
}

export const navItemEventMapping: ObjectLiteral = {
  [STRAPI_SECTION_IDS.MEET]: 'view_meet',
  [STRAPI_SECTION_IDS.HOWITWORKS]: 'view_how_it_works',
  [STRAPI_SECTION_IDS.ECOSYSTEM]: 'view_ecosystem',
  [STRAPI_SECTION_IDS.REWARDSCALCULATOR]: 'view_reward_calculator',
  [STRAPI_SECTION_IDS.AUDITS]: 'view_security',
  [STRAPI_SECTION_IDS.PARTNERS]: 'view_partners',
  [STRAPI_SECTION_IDS.COMMINITY]: 'view_communities',
  [STRAPI_SECTION_IDS.SUBSCRIBE]: 'view_newsletter',
};
