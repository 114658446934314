import { ReactNode } from 'react';

import { Box, BoxProps, Container, Text } from '@chakra-ui/react';

interface Props extends BoxProps {
  title?: string;
  subTitle?: string;
  isH2?: boolean;
  children: ReactNode;
}

const Section = ({ title, subTitle, children, isH2, ...rest }: Props) => (
  <Box
    w="full"
    py={{ base: '64px', lg: '80px' }}
    scrollMargin={{ base: '80px' }}
    {...rest}
  >
    <Container
      textAlign="center"
      p={{ base: '0 16px', lg: 0 }}
      maxW="1192px"
      display="flex"
      flexDir="column"
      alignItems="center"
    >
      {title && (
        <Text
          as={isH2 ? 'h2' : 'p'}
          fontWeight="700"
          fontSize={{ base: '24px', lg: '40px' }}
          lineHeight={{ base: '28px', lg: '48px' }}
          maxW="680px"
        >
          {title}
        </Text>
      )}

      {subTitle && (
        <Text
          mt="12px"
          fontWeight="500"
          fontSize={{ base: '12px', lg: '16px' }}
          lineHeight={{ base: '18px', lg: '24px' }}
          color="textSecondary"
          maxW="536px"
          dangerouslySetInnerHTML={{ __html: subTitle }}
        />
      )}
      {children}
    </Container>
  </Box>
);

export default Section;
