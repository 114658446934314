import { useContext } from 'react';

import jsonPath from 'jsonpath';

// Context
import { PageContext, PageContextData } from '@/providers/PageProvider';

function usePageData(): PageContextData;
function usePageData(path: string): any[];

function usePageData(path?: string | undefined): PageContextData | any[] {
  const data = useContext(PageContext);

  if (path) {
    const queryResult = jsonPath.query(data, path);
    if (queryResult) {
      return queryResult;
    }
  }
  return data;
}

export default usePageData;
