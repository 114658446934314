import { ReactNode, useMemo } from 'react';

import Link, { LinkProps } from 'next/link';
import { resolve } from 'url';

interface Props extends LinkProps {
  children: ReactNode;
}

const BaseLink = ({ href, as, children, ...rest }: Props) => {
  const newHref = useMemo(() => {
    let baseURI_as = (as || href) as string;

    if (baseURI_as.startsWith('/')) {
      baseURI_as = '.' + href;
      if (typeof document !== 'undefined') {
        baseURI_as = resolve(document.baseURI, baseURI_as);
      }
    }
    return baseURI_as;
  }, [as, href]);

  return (
    <Link {...rest} href={newHref}>
      {children}
    </Link>
  );
};

export default BaseLink;
